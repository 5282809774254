* {
  position: relative;
}

html,
body,
#root,
#root > div {
  min-height: 100%;
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #264e70;
  color: #fffdf6;
  font-family: 'Inter', sans-serif;
}

button {
  font-family: 'Inter', sans-serif;
}
